/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
// React component
import React, { useState, useRef } from 'react';
import './ChipScroll.css'; // make sure to create this CSS file

const Chip = ({updateComment, vibe}) => {
  return (
    <div
      className="chip" 
      onClick={() => updateComment(vibe)}
    >
        {vibe}
    </div>
  )
}

const Chips = ({goodVibes, updateComment}) => {
  let output = [];
  for (const vibe of goodVibes) {
    output.push(
      <Chip 
        key={vibe.substring(0, 6)}
        updateComment={updateComment} vibe={vibe}
      />
    )
  }
  return output
}

const DraggableScroll = ({ children }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [startPosition, setStartPosition] = useState(0);
  const [scrollStart, setScrollStart] = useState(0);
  const containerRef = useRef(null);

  const onMouseDown = (event) => {
    setIsDragging(true);
    setStartPosition(event.clientX);
    setScrollStart(containerRef.current.scrollLeft);
  };

  const onMouseMove = (event) => {
    if (!isDragging) return;
    const dx = event.clientX - startPosition;
    containerRef.current.scrollLeft = scrollStart - dx;
  };

  const onMouseUp = () => {
    setIsDragging(false);
  };

  const onMouseLeave = () => {
    if (isDragging) {
      setIsDragging(false);
    }
  };

  return (
    <div
      ref={containerRef}
      className="chip-scroll"
      onMouseDown={onMouseDown}
      onMouseMove={onMouseMove}
      onMouseUp={onMouseUp}
      onMouseLeave={onMouseLeave}
      style={{ cursor: isDragging ? 'grabbing' : 'grab', overflowX: 'auto' }}
    >
      {children}
    </div>
  );
};

const ChipScroll = ({goodVibes, updateComment}) => {
  return (
    <div className="chip-container">
      <h6>Good Vibes</h6>
      <DraggableScroll>
        <Chips goodVibes={goodVibes} updateComment={updateComment} />
      </DraggableScroll>
    </div>
  );
}

export default ChipScroll;
