/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ErrorMessage from '../../components/ErrorMessage'

import { createForum } from '../axiosService';  // adjust path as needed

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCircleArrowLeft, faFloppyDisk} from '@fortawesome/free-solid-svg-icons';

import "./NewForum.css"

const NewForum = () => {
    const navigate = useNavigate();  // Get the navigate function
    const [newForumName, setNewForumName] = useState('');
    const [newForumDescription, setNewForumDescription] = useState('');

    const [errorMessage, setErrorMessage] = useState(null);

    const [visibleDates, setVisibleDates] = useState({});

    const iconLookup = {
        faCircleArrowLeft
    }

    const handleCreateForum = async () => {
        try {
            const data = await createForum({ name: newForumName, description: newForumDescription });
            setNewForumName('');
            setNewForumDescription('');

            navigate(`/forum/${data.id}`);  // Navigate to the new forum using its id
        } catch (error) {
            console.error("Error creating forum:", error);
            setErrorMessage(error.response.data.error)
            if (error.response.status == 429) {
                setErrorMessage('Try participating in an existing forum')
            }
        }
    };

    const toggleDateVisibility = (messageId) => {
        setVisibleDates(prevVisibleDates => ({
            ...prevVisibleDates,
            [messageId]: !prevVisibleDates[messageId],
        }));
    };

    return (
        <div>
            <div className='forum-nav'>     
                <Link to={`/`}>
                            <div className='button'><FontAwesomeIcon icon={faCircleArrowLeft} /> Back </div>
                </Link>

                <div className='button' onClick={handleCreateForum}><FontAwesomeIcon icon={faFloppyDisk} />Create Forum</div>
            </div>
            {errorMessage &&
                <ErrorMessage errorMessage={errorMessage} />
            }
            <div> 
                <div className='input-group'>
                    <div className='input-container'>
                        <label>
                            Name
                        </label>
                        <input 
                            value={newForumName}
                            onChange={(e) => setNewForumName(e.target.value)}
                            placeholder="Forum Name"
                        />
                    </div>
                </div>
                <div className='input-group'>
                    <div className='input-container'>
                        <label>
                            Description
                        </label>
                        <input 
                            value={newForumDescription}
                            onChange={(e) => setNewForumDescription(e.target.value)}
                            placeholder="Forum Description"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewForum;
