/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';


import './ForumOverview.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import ErrorMessage from '../../components/ErrorMessage';
import config from '../../config';
import { FacebookShareButton, TwitterShareButton, EmailShareButton } from 'react-share';

const ForumOverview = ({ forum, profileDetails }) => {
  const [showCopiedMessage, setShowCopiedMessage] = useState(false);

  const checkProfilePicture = (message) => {
        let imagePath = 'https://revibe-life-assets.s3.amazonaws.com/critter021.webp';
        const pattern = /^critter\d{3}\.webp$/;

        const profilePicture = profileDetails[message.author_id]?.profile_picture;

        if (profilePicture != null && pattern.test(profilePicture)) {
            imagePath = `https://revibe-life-assets.s3.amazonaws.com/${profileDetails[message.author_id].profile_picture}`
        }

        return imagePath;
  }

  // IDEA: Refactor to a common utility
  function truncateCharacters(str, maxLength) {
    if (str.length <= maxLength) {
        return str; // Returns original string if string is equal to or less than max length
    } else {
        const truncatedString = str.substring(0, maxLength); // Get the substring up to the maxLength
        // Find the last space within the truncated substring to avoid cutting off a word
        const lastSpaceIndex = truncatedString.lastIndexOf(' ');
        if (lastSpaceIndex !== -1) {
        return truncatedString.substring(0, lastSpaceIndex) + '...'; // Add dots at the last space
        } else {
        return truncatedString + '...'; // If no space found, add dots at the end of the substring
        }
    }
  }


  let org = {}
  const thisForumURL = config.createApiUrl('forum', `/forum/${forum.id}`, config.URL_TYPES.BASE)

  const sharedText = `
   Hey there!

   I just found a forum on Revibe that you might find interesting. It's a great way to meet like-minded people and try something new!

   Forum: ${forum.name}
   ${forum.description ? `Description: ${forum.description}` : ''}

   Check out this forum on Revibe: ${thisForumURL}

   Explore this forum and many others to find local activities, events, and connections that speak to you.

  `;

  const xSharedText = `
  Forum Name: ${forum.name}
  ${forum.description ? `Description: ${forum.description}` : ''}

  Check out this fourm on #Revibe: ${thisForumURL}
  
 `;

 const copyUrlToClipboard = () => {
 navigator.clipboard.writeText(thisForumURL)
     .then(() => {
         setShowCopiedMessage(true);
         setTimeout(() => setShowCopiedMessage(false), 4000); // Message disappears after 2 seconds
     })
     .catch(err => {
         console.error('Failed to copy URL: ', err);
     });
 };


  return (
    <div className='card'>
    {showCopiedMessage &&
      <div className="copied-message">
        <ErrorMessage errorMessage={'Copied Fourm Link!'} />
      </div>
    }

    <div className='forum-heading'>
        <div className='user-details'>
            <img 
                className='profile-pic'
                src={checkProfilePicture(forum)}
            />
            <div>
                {
                    profileDetails[forum.author_id]?.username ?
                    truncateCharacters(
                        profileDetails[forum.author_id]?.username, 16
                    ) :
                    '(No username)'
                }
            </div>
        </div>
    </div>
    <div className='forum-standard-text'>
        <h3>{forum.name}</h3>
        <p>{forum.description}</p>
    </div>
    
    <Link to={`/forum/${forum.id}`}>
        <div className='button'>View Page</div>
    </Link>

    <div className="share_buttons_vertical">
<div
    className={`share_button vertical_share_button ${showCopiedMessage ? 'active' : ''}`} 
    onClick={() => {
    if(!showCopiedMessage) {
        copyUrlToClipboard()
    }
    }}
>
    <FontAwesomeIcon width={32} icon={faLink} />
</div>
</div>
<div className="share_buttons">
<FacebookShareButton
    quote={sharedText}
    hashtag={"revibe"}
    url='https://www.revibe.life'
    className="share_button"
    >
    <svg viewBox="0 0 64 64" width="32" height="32">
        <circle cx="32" cy="32" r="31" fill="#0965FE"></circle>
        <path d="M34.1,47V33.3h4.6l0.7-5.3h-5.3v-3.4c0-1.5,0.4-2.6,2.6-2.6l2.8,0v-4.8c-0.5-0.1-2.2-0.2-4.1-0.2 c-4.1,0-6.9,2.5-6.9,7V28H24v5.3h4.6V47H34.1z" fill="white">
        </path>
    </svg>
</FacebookShareButton>

<TwitterShareButton
    title={xSharedText}
    url='revibe.life'
    className="share_button"
>
    <svg viewBox="0 0 64 64" width="32" height="32">
    <circle cx="32" cy="32" r="32" fill="#000000"></circle>
    <path d="M 41.116 18.375 h 4.962 l -10.8405 12.39 l 12.753 16.86 H 38.005 l -7.821 -10.2255 L 21.235 47.625 H 16.27 l 11.595 -13.2525 L 15.631 18.375 H 25.87 l 7.0695 9.3465 z m -1.7415 26.28 h 2.7495 L 24.376 21.189 H 21.4255 z" fill="white"></path>
    </svg>
</TwitterShareButton>

<EmailShareButton
    subject={`Discover Something new with Revibe Life`}
    body={sharedText}
    url="Improve with Revibe! Find more local events. https://www.revibe.life"
    className="share_button"
>
    <svg viewBox="0 0 64 64" width="32" height="32">
        <circle cx="32" cy="32" r="31" fill="#ff5bad"></circle>
        <path d="M17,22v20h30V22H17z M41.1,25L32,32.1L22.9,25H41.1z M20,39V26.6l12,9.3l12-9.3V39H20z" fill="white"></path>
    </svg>
</EmailShareButton>
</div>

</div>

  );
};

export default ForumOverview;
