/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';

import { getAllContentByForum , fetchProfileDetails, getThreadsByForum, updateForum, getForum, createMessage, getMessagesByThread, getEventMappingByForum } from '../axiosService';  // assuming you've put Axios methods in a file called axiosService.js
import { useParams } from 'react-router-dom';
import { Link, useNavigate } from 'react-router-dom';
import ErrorMessage from '../../components/ErrorMessage';
import ChipScroll from '../../components/ChipScroll';
import Messages from '../../components/Messages';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faLink } from '@fortawesome/free-solid-svg-icons';
import { faBinoculars, faCircleXmark, faLightbulb, faRocket, faThumbsUp, faCircleDown, faFilePen, faMagnifyingGlassPlus, faCircleLeft, faPaperPlane } from '@fortawesome/free-solid-svg-icons';

import { FacebookShareButton, TwitterShareButton, EmailShareButton } from 'react-share';

import config from '../../config';

import "./ForumPage.css"

const LINKED_EVENT_BASE_URL = config.createApiUrl('events', '/event/', config.URL_TYPES.BASE);

// IDEA: Move placeholder function to a utility
const placeholders = [
    "Seek clarity",
    "Show gratitude",
    "Add your insight",
    "Suggest ideas",
    "Use an icon to engage",
    "Select an icon to express",
    "Select an icon to enrich",
    "Use an icon to innovate",
    "Tap for a deeper understanding",
    "Tap to offer alternatives"
]

const iconLookup = {
    inquire: faBinoculars,
    appreciate: faThumbsUp,
    innovate: faLightbulb,
    enhance: faRocket,
};

const termLookup = {
    inquire: 'Explore',
    appreciate: 'Value',
    innovate: 'Renew',
    enhance: 'Boost',
}

const vibeIdLookup = {
    inquire: 1,
    appreciate: 2,
    innovate: 3,
    enhance: 4,
}

const goodVibesLookup = {
    inquire: [
        "Could you please elaborate more?",
        "Can you provide further details?",
        "Would you like to expand on that?",
        "I'm interested in your thoughts!",
        "What are your insights on this?"
    ],
    appreciate: [
        "Great input, thanks for sharing!",
        "Your insights are valued here.",
        "I appreciate your valuable contribution.",
        "Thanks for your thoughtful comment!",
        "Your feedback is much appreciated."
    ],
    innovate: [
        "How about a new approach?",
        "What if we tried something different?",
        "I'm thinking about this differently.",
        "How can we think about this?",
        "Let's consider it from a new perspective."
    ],
    enhance: [
        "How can we refine this idea?",
        "What resources can we leverage together?",
        "Let's propel this forward!",
        "Can we outline a plan?",
        "I see the long-term impact."
    ],
}

const ForumPage = () => {
    let { id } = useParams();
    const forumId = id;

    const [showCopiedMessage, setShowCopiedMessage] = useState(false);

    const [placeholder, setPlaceholder] = useState('');
    
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [commentModalIsOpen, setCommentModalIsOpen] = useState(false);
    const [modalType, setModalType] = useState('')

    const [threads, setThreads] = useState([]);
    const [newThreadData, setNewThreadData] = useState({ author_id: '', forum_id: forumId, status_id: 1 });  // Sample data
    const [forumDetails, setForumDetails] = useState({ author_id: '', name: '', description: '' });
    const [isForumOwner, setIsForumOwner] = useState(false)

    const [comment, setComment] = useState('');
    
    const [eventData, setEventData] = useState(null);

    const [errorMessage, setErrorMessage] = useState(null);

    // Additional state for profile details
    const [profileDetails, setProfileDetails] = useState({});

    const fetchAllContent = async () => {
        try {
            const data = await getAllContentByForum(forumId);

            if (data.error) {
                console.error("Error fetching content:", data.error);
                return;
            }

            // Set forum details
            setForumDetails(data.forum);

            // Set threads and their messages
            setThreads(data.content);

            setIsForumOwner(data.is_forum_owner)

            // Extract user IDs from threads and fetch profile details
            const userIds = extractUserIds(data.content);
            if (userIds.length > 0) {
                const profileData = await fetchProfileDetails(userIds);
                setProfileDetails(profileData.reduce((acc, profile) => {
                    acc[profile.id] = profile; // Storing profiles by username for easy access
                    return acc;
                }, {}));
            }

            
            const eventResponse = await getEventMappingByForum(forumId);

            if (eventResponse.message === "Mapping found") {
                setEventData(eventResponse.data)
            }

            if (eventResponse.error) {
                console.error("Error fetching content:", data.error);
                return;
            }

        } catch (error) {
            console.error("Error fetching content:", error);
        }
    };


    // Fetch threads of the forum when component mounts
    useEffect(() => {
        fetchAllContent();
        
        const randomIndex = Math.floor(Math.random() * placeholders.length);
        setPlaceholder(placeholders[randomIndex]);

    }, [forumId, placeholders]);

    // Function to extract user IDs
    const extractUserIds = (content) => {
        // Assuming each thread has an 'author_id' or similar attribute
        // IDEA: we would ideally get the author_id from all the messages
        const answer = [...new Set(content.map(message => message.messages[0] ? message.messages[0].author_id : 0 ))]

        return answer;
    };

    const addComment = async () => {
        try {
            const data = await createMessage({
                forum_id: forumId,
                thread_id: null,
                content: comment,
                vibe_type_id: vibeIdLookup[modalType]
            });
            
            setComment('');

            fetchAllContent();
            setErrorMessage(null);
            setModalType('');
        } catch (error) {
            if (error.response) {
                // Request was made and server responded with a status code
                if (error.response.status === 400) {
                   setErrorMessage(error.response.data.error);
                    // Display an error message to the user
                } else if (error.response.status == 429) {
                    setErrorMessage('Take a deep breath, wait a minute, then share your vibe')
                }
            } else {
                // Something happened in setting up the request that triggered an error
                setErrorMessage('There was a server error');
                // Handle the error as needed
                console.log(error)
            }
        }
    }
        
    const reactionClick = (reaction) => {
        // Open modal based on the clicked reaction
        setPlaceholder('Share a good vibe')
        setCommentModalIsOpen(true);
        setModalType(reaction);
    };
    
    const openForumDetailsModal = () => {
        setModalIsOpen(true);
        setModalType('magnify')
    }

    // IDEA: Refactor to a common utility
    function truncateCharacters(str, maxLength) {
        if (str.length <= maxLength) {
          return str; // Returns original string if string is equal to or less than max length
        } else {
          const truncatedString = str.substring(0, maxLength); // Get the substring up to the maxLength
          // Find the last space within the truncated substring to avoid cutting off a word
          const lastSpaceIndex = truncatedString.lastIndexOf(' ');
          if (lastSpaceIndex !== -1) {
            return truncatedString.substring(0, lastSpaceIndex) + '...'; // Add dots at the last space
          } else {
            return truncatedString + '...'; // If no space found, add dots at the end of the substring
          }
        }
    }

    const forum = forumDetails;
    const thisForumURL = config.createApiUrl('forum', `/forum/${forum.id}`, config.URL_TYPES.BASE)

    const sharedText = `
     Hey there!
    
     I just found a forum on Revibe that you might find interesting. It's a great way to meet like-minded people and try something new!
    
     Forum: ${forum.name}
     ${forum.description ? `Description: ${forum.description}` : ''}
    
     Check out this forum on Revibe: ${thisForumURL}
    
     Explore this forum and many others to find local activities, events, and connections that speak to you.
    
    `;
  
    const xSharedText = `
    Forum Name: ${forum.name}
    ${forum.description ? `Description: ${forum.description}` : ''}
    
    Check out this fourm on #Revibe: ${thisForumURL}
        
    `;
  
    const copyUrlToClipboard = () => {
    navigator.clipboard.writeText(thisForumURL)
        .then(() => {
            setShowCopiedMessage(true);
            setTimeout(() => setShowCopiedMessage(false), 4000); // Message disappears after 2 seconds
        })
        .catch(err => {
            console.error('Failed to copy URL: ', err);
        });
    };
  

    return (
        <div id='forum-page'>
            {/* IDEA: Format this to be a more polished modal */}
            <Modal className='chat-modal' isOpen={modalIsOpen}>
                <div className='chat_content'>
                    <div className='chat_close' onClick={() => setModalIsOpen(false)}>
                        <FontAwesomeIcon icon={faCircleXmark} size='lg'/>
                    </div>
                    <div className='rules_container'> 
                        <h4>{forumDetails.name}</h4>
                        <h5>{forumDetails.description}</h5>
                    </div>
                </div>
            </Modal>

            <Modal className='chat-modal' isOpen={commentModalIsOpen}>

                {modalType !== 'magnify' ? (
                    <div className='greener'>
                        <div className='chat-modal-info_area'>
                            <div className='chat-modal-info_area-heading'>
                                <h4>
                                    <FontAwesomeIcon icon={iconLookup[modalType]} size="xl" />
                                    {termLookup[modalType]} the Vibe!
                                </h4>
                                <div className='modal-back' onClick={() => setCommentModalIsOpen(false)}>
                                    <FontAwesomeIcon icon={faCircleXmark} size='lg'/>
                                </div>
                            </div>
                            <div className='chat-modal-info_area-body'>
                                <Messages threads={threads.slice(-2)} profileDetails={profileDetails} truncateCharacters={truncateCharacters}/>
                            </div>
                            <h6 className='chat-modal-info_area-filler_text'>
                                Craft a vibrant tune!
                            </h6>
                        </div>
                        <div className='menu-area'>

                            <ChipScroll goodVibes={goodVibesLookup[modalType]} updateComment={setComment} modalType={modalType} />

                            <div className='quick-comment'>
                                <div className='quick-comment-actions'>
                                    <div onClick={() => setCommentModalIsOpen(false)}>
                                        <FontAwesomeIcon icon={faCircleDown} size='xl'/>
                                    </div>
                                    <div className="search_input-container">
                                        <textarea 
                                            type="text"
                                            value={comment} 
                                            onChange={(e) => setComment(e.target.value)}
                                            placeholder={placeholder}
                                            />
                                    </div>
                                    <div onClick={() => {
                                        addComment();
                                        setCommentModalIsOpen(false);
                                    }}>
                                        <FontAwesomeIcon icon={faPaperPlane} size="xl" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className='forum-standard-text magnified'> 
                        <h1>{forumDetails.name}</h1>
                        <h3>{forumDetails.description}</h3>
                    </div>
                )}
            </Modal>

            <div className='forum-items'>
                {eventData &&
                    <>
                        <div className='gradient-border' />
                        <div className='connected-event_group'>
                            <div className='connected-event_group-info'>
                                <h6 className='connected_vibe_label'>
                                    {truncateCharacters(eventData.event_name, 24)}
                                </h6>
                            </div>

                            <Link to={`${LINKED_EVENT_BASE_URL}${eventData.event_id}`}>
                                <div className='button gradient_button'>
                                    View
                                </div>
                            </Link>
                        </div>
                        <div className='gradient-border' />
                    </>
                }
                <div className='forum-standard-text forum-details'> 
                    <h4>
                        {truncateCharacters(forumDetails.name, 24)}
                    </h4>
                    <p className='forum-page-description'>
                        {truncateCharacters(forumDetails.description, 48)}
                    </p>
                    {/* IDEA: Change to the result of isAbleToEdit with jwt() token call */}
                    <div className='forum-nav'>
                        { isForumOwner &&
                            <Link to={`/forum/${forumId}/edit`}>
                                <div className='button'><FontAwesomeIcon icon={faFilePen} /> Edit </div>
                            </Link>
                        }
                        {
                            !isForumOwner &&
                            <div></div>
                        }
                        <span className="forum-magnify magnify-right" onClick={openForumDetailsModal}>
                            <FontAwesomeIcon icon={faMagnifyingGlassPlus} />
                            Show Details
                        </span>
                    </div>
                    {errorMessage &&
                        <ErrorMessage errorMessage={errorMessage}/> 
                    }

                    <div className="share_buttons">
                        <FacebookShareButton
                            quote={sharedText}
                            hashtag={"revibe"}
                            url='https://www.revibe.life'
                            className="share_button"
                            >
                            <svg viewBox="0 0 64 64" width="32" height="32">
                                <circle cx="32" cy="32" r="31" fill="#0965FE"></circle>
                                <path d="M34.1,47V33.3h4.6l0.7-5.3h-5.3v-3.4c0-1.5,0.4-2.6,2.6-2.6l2.8,0v-4.8c-0.5-0.1-2.2-0.2-4.1-0.2 c-4.1,0-6.9,2.5-6.9,7V28H24v5.3h4.6V47H34.1z" fill="white">
                                </path>
                            </svg>
                        </FacebookShareButton>

                        <TwitterShareButton
                            title={xSharedText}
                            url='revibe.life'
                            className="share_button"
                        >
                            <svg viewBox="0 0 64 64" width="32" height="32">
                            <circle cx="32" cy="32" r="32" fill="#000000"></circle>
                            <path d="M 41.116 18.375 h 4.962 l -10.8405 12.39 l 12.753 16.86 H 38.005 l -7.821 -10.2255 L 21.235 47.625 H 16.27 l 11.595 -13.2525 L 15.631 18.375 H 25.87 l 7.0695 9.3465 z m -1.7415 26.28 h 2.7495 L 24.376 21.189 H 21.4255 z" fill="white"></path>
                            </svg>
                        </TwitterShareButton>

                        <EmailShareButton
                            subject={`Discover Something new with Revibe Life`}
                            body={sharedText}
                            url="Improve with Revibe! Find more local events. https://www.revibe.life"
                            className="share_button"
                        >
                            <svg viewBox="0 0 64 64" width="32" height="32">
                                <circle cx="32" cy="32" r="31" fill="#ff5bad"></circle>
                                <path d="M17,22v20h30V22H17z M41.1,25L32,32.1L22.9,25H41.1z M20,39V26.6l12,9.3l12-9.3V39H20z" fill="white"></path>
                            </svg>
                        </EmailShareButton>

                        <div
                            className={`share_button vertical_share_button ${showCopiedMessage ? 'active' : ''}`} 
                            onClick={() => {
                            if(!showCopiedMessage) {
                                copyUrlToClipboard()
                            }
                            }}
                        >
                            <FontAwesomeIcon width={32} icon={faLink} />
                        </div>
                    </div>
                </div>
                <div className="forum-comments">
                    {showCopiedMessage &&
                        <div className="copied-message">
                            <ErrorMessage errorMessage={'Copied Fourm Link!'} />
                        </div>
                    }
                    {
                        threads.length === 0 &&
                        <div style={{paddingTop: "5rem"}} className='chat-modal-info_area-filler_text'>
                            Craft a vibrant tune!
                        </div>
                    }
                    <Messages threads={threads} profileDetails={profileDetails} truncateCharacters={truncateCharacters}/>
                </div>

                {/* IDEA: Make this look nicer */}
                <div className='forum-user_interaction '>
                    <div className='row revibe_actions'>
                        <div onClick={() => reactionClick('appreciate')}>
                            <FontAwesomeIcon icon={faThumbsUp} size="xl" />
                        </div>

                        <div onClick={() => reactionClick('inquire')}>
                            <FontAwesomeIcon icon={faBinoculars} size="xl" />
                        </div>

                        <div onClick={() => reactionClick('innovate')}>
                            <FontAwesomeIcon icon={faLightbulb} size="xl" />
                        </div>

                        <div onClick={() => reactionClick('enhance')}>
                            <FontAwesomeIcon icon={faRocket} size="xl" />
                        </div>
                    </div>

                    <div>
                        <div className='quick-comment-actions'>
                            <Link to={`/`}>
                                <FontAwesomeIcon icon={faCircleLeft} size="xl" />
                            </Link>
                            <div className="search_input-container">
                                <textarea 
                                    type="text"
                                    value={comment} 
                                    onChange={(e) => setComment(e.target.value)}
                                    placeholder={placeholder}
                                />
                            </div>
                            <div onClick={() => addComment()}>
                                <FontAwesomeIcon icon={faPaperPlane} size="xl" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ForumPage;
