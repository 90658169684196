/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import Rules from '../Rules/Rules';
import Search from '../../components/Search';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment, faGamepad, faBookBookmark, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { faCalendarDays, faComments, faMagnifyingGlass, faCircleUser } from '@fortawesome/free-solid-svg-icons';

import Modal from 'react-modal';

import config from '../../config';

import './ChatPage.css';

const eventURL = config.createApiUrl('events', `/`, config.URL_TYPES.BASE);
const identityURL = config.createApiUrl('identity', `/`, config.URL_TYPES.BASE);

const ChatPage = () => {
    const [forums, setForums] = useState([]);
    
    const [modalIsOpen, setModalIsOpen] = useState(false);

    return (
        <div className="chatPage">
            <Modal className="chat-modal" isOpen={modalIsOpen}>
                <div className='chat_content'>
                    <div className='chat_close' onClick={() => setModalIsOpen(false)}>
                        <FontAwesomeIcon icon={faCircleXmark} size='lg'/>
                    </div>
                    <Rules />
                </div>
            </Modal>

            <div className="forumList">
                <div className='talkHead'>
                    <div>
                        <h2>Discussions</h2>
                        <h3>Public Vibes</h3>
                    </div>

                    <div>
                        <div onClick={() => setModalIsOpen(true)} className='button'>
                            <FontAwesomeIcon icon={faBookBookmark}/> Rules
                        </div>
                    </div>           
                </div>
            </div>
            
            <div className='create-forum c2a'>
                <Link to={`/forum/new`}>
                        <div className='gradient_button button'><FontAwesomeIcon icon={faComment} />Start a chat</div>
                </Link>
                <h6>
                    Create a new forum
                </h6>
            </div>

            {/* Search component renders all forum items */}
            <Search />

            <div className='nav' style={{zIndex: 0}}>
                <Link
                className="fa-button"
                to={`${eventURL}`}
                >
                    <FontAwesomeIcon
                        icon={faCalendarDays}
                        size="xl" />
                </Link>
                <Link
                className="fa-button"
                to="/"
                >
                    <FontAwesomeIcon
                        icon={faComments}
                        size="xl" />
                </Link>
                <Link
                className="fa-button"
                to={`${eventURL}search`}
                >
                    <FontAwesomeIcon
                        icon={faMagnifyingGlass}
                        size="xl" />
                </Link>
                <Link
                className="fa-button"
                to={`${identityURL}user-profile`}
                >
                    <FontAwesomeIcon
                        icon={faCircleUser}
                        size="xl" />
                </Link>
            </div>
        </div>
    );
};

export default ChatPage;
