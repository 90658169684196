/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';

import './App.css';

import EditForum from './pages/EditForum/EditForum';
import NewForum from './pages/NewForum/NewForum';
import ForumPage from './pages/ForumPage/ForumPage';
import ErrorMessage from './components/ErrorMessage';
import SimulatedEntity from './components/SimulatedEntity';
import ForumPageProto from './pages/ForumPageProto';
import ChatPage from './pages/ChatPage';
import Quiz from './pages/Quiz';
import Rules from './pages/Rules';
import GetStarted from './pages/GetStarted';

const entityData = {
  profileImageURL: 'path/to/image.jpg',
  name: 'Robin Smith',
  role: 'Materials Scientist',
  affinity: 'Auxonic',
  affinityPercent: 87.8,
  description: "Robin specializes in materials science. It's likely that Robin will likely work well with Pat Miller who leads many programs. Psihesion would increase."
};

// Create a custom hook to encapsulate the logic for determining if the nav should be hidden
const useHideNav = (rootPaths) => {
  const location = useLocation(); // Hook to get the current location object
  const hideNav = location.pathname === '/auth' || rootPaths.some(path => location.pathname.startsWith(path));
  return hideNav;
};

const AppContent = () => {
  const rootPaths = ['/forum']; // Add other root paths you want to check
  const hideNav = useHideNav(rootPaths);

  useEffect(() => {
    const adjustHeight = () => {
      const mainElement = document.getElementById('main');
      if (mainElement) {
        const navHeight = hideNav ? 0 : 3 * 16; // 3rem to pixels
        mainElement.style.height = `${window.innerHeight - navHeight}px`;
      }
    };

    adjustHeight();
    window.addEventListener('resize', adjustHeight);
    
    return () => {
      window.removeEventListener('resize', adjustHeight);
    };
  }, [hideNav]);

  return (
    <div style={{ display: "flex", width: "100vw" }}>
      <div id="main">
        <div className='content'>
          {/* Your Routes here */}
          <Routes>
            <Route path="/Proto" element={<ForumPageProto />} />
            <Route path="/" element={<ChatPage />} />
            <Route path="/auth" element={<GetStarted />} />
            <Route path="/rules" element={<Rules />} />
            <Route path="/quiz" element={<Quiz />} />
            <Route path="/forum/:id" element={<ForumPage />} />
            <Route path="/forum/new" element={<NewForum />} />
            <Route path="/forum/:id/edit" element={<EditForum />} />
            {/* <Route path="/message/:id" element={<MessagePage/>}/> */}
            <Route path="/Test" element={

              <div>

                <ErrorMessage errorMessage={'BOOP'}/> 

              </div>
            } />
            <Route path="/sim" element={
              <SimulatedEntity entityData={entityData} />
            } />
          </Routes>
        </div>
      </div>
    </div>
  );
};

const App = () => {
  console.log('Forum')

  return (
    <Router>
      <AppContent />
    </Router>
  );
};

export default App;
