/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import React, { useState } from 'react';
import './Quiz.css';
import { Link, useNavigate } from 'react-router-dom';

const App = () => {
  const counts = {
    q1: { A: 0, B: 0, C: 0, D: 0 },
    q2: { A: 0, B: 0, C: 0, D: 0 },
    q3: { A: 0, B: 0, C: 0, D: 0 },
    q4: { A: 0, B: 0, C: 0, D: 0 },
    q5: { A: 0, B: 0, C: 0, D: 0 },
  };

  const [answers, setAnswers] = useState({});
  const [result, setResult] = useState(null);
  const [introversionPercentage, setIntroversionPercentage] = useState(0);
  const [extraversionPercentage, setExtraversionPercentage] = useState(0);

  const handleAnswerChange = (question, value) => {
    setAnswers({ ...answers, [question]: value });
  };

  const calculateResult = () => {
    if (Object.keys(answers).length < 5) {
      alert("Please answer all questions before submitting.");
      return;
    }

    Object.keys(answers).forEach((question) => {
      counts[question][answers[question]] += 1;
    });

    const introversionScore =
      counts.q1.A + counts.q2.A + counts.q3.A + counts.q4.A + counts.q5.A;
    const extraversionScore =
      counts.q1.B + counts.q2.B + counts.q3.B + counts.q4.B + counts.q5.B;

    let resultText = '';
    if (introversionScore > extraversionScore) {
      resultText = 'You have introverted tendencies.';
    } else if (extraversionScore > introversionScore) {
      resultText = 'You have extraverted tendencies.';
    } else {
      resultText = 'You have a balanced mix of introverted and extraverted tendencies.';
    }

    setResult(resultText);

    // Calculate percentages
    const totalQuestions = 5;
    const introversionPercentage = (introversionScore / totalQuestions) * 100;
    const extraversionPercentage = (extraversionScore / totalQuestions) * 100;

    // Set the percentages in state
    setIntroversionPercentage(introversionPercentage);
    setExtraversionPercentage(extraversionPercentage);
  };

  const renderScale = () => {
    const totalQuestions = 5;
    const introversionScore =
      counts.q1.A + counts.q2.A + counts.q3.A + counts.q4.A + counts.q5.A;
    const extraversionScore =
      counts.q1.B + counts.q2.B + counts.q3.B + counts.q4.B + counts.q5.B;

    const introversionPercentage = (introversionScore / totalQuestions) * 100;
    const extraversionPercentage = (extraversionScore / totalQuestions) * 100;

    const markerPosition = extraversionPercentage; // Use extraversion percentage as the marker position

    return (
      <div id="scale">
        <h2>Introversion-Extraversion Scale:</h2>
        <div className="scale-bar">
          <div className="introversion" style={{ width: `${introversionPercentage}%` }}>
            Introversion
          </div>
          <div
            className="extraversion"
            style={{ width: `${extraversionPercentage - introversionPercentage}%` }}
          >
            Extraversion
          </div>
          <div className="marker" style={{ left: `${markerPosition}%` }}></div>
        </div>
        <p>0% Introversion - 100% Extraversion</p>
      </div>
    );
  };

  return (
    <div className="quiz-container">
      <h1>Personality Quiz</h1>

      <div className="question" id="question1">
        <p>1. What is your favorite color?</p>
        <label className="button">
          <input
            type="radio"
            name="q1"
            value="A"
            onChange={() => handleAnswerChange('q1', 'A')}
          />
          A. Blue
        </label>
        <label className="button">
          <input
            type="radio"
            name="q1"
            value="B"
            onChange={() => handleAnswerChange('q1', 'B')}
          />
          B. Red
        </label>
        <label className="button">
          <input
            type="radio"
            name="q1"
            value="C"
            onChange={() => handleAnswerChange('q1', 'C')}
          />
          C. Green
        </label>
        <label className="button">
          <input
            type="radio"
            name="q1"
            value="D"
            onChange={() => handleAnswerChange('q1', 'D')}
          />
          D. Yellow
        </label>
      </div>

      <div className="question" id="question2">
        <p>2. What is your preferred way to spend a weekend?</p>
        <label className="button">
          <input
            type="radio"
            name="q2"
            value="A"
            onChange={() => handleAnswerChange('q2', 'A')}
          />
          A. Reading a book
        </label>
        <label className="button">
          <input
            type="radio"
            name="q2"
            value="B"
            onChange={() => handleAnswerChange('q2', 'B')}
          />
          B. Going out with friends
        </label>
        <label className="button">
          <input
            type="radio"
            name="q2"
            value="C"
            onChange={() => handleAnswerChange('q2', 'C')}
          />
          C. Hiking or outdoor activities
        </label>
        <label className="button">
          <input
            type="radio"
            name="q2"
            value="D"
            onChange={() => handleAnswerChange('q2', 'D')}
          />
          D. Watching movies or TV shows
        </label>
      </div>

      <div className="question" id="question3">
        <p>3. What is your favorite type of music?</p>
        <label className="button">
          <input
            type="radio"
            name="q3"
            value="A"
            onChange={() => handleAnswerChange('q3', 'A')}
          />
          A. Classical
        </label>
        <label className="button">
          <input
            type="radio"
            name="q3"
            value="B"
            onChange={() => handleAnswerChange('q3', 'B')}
          />
          B. Pop
        </label>
        <label className="button">
          <input
            type="radio"
            name="q3"
            value="C"
            onChange={() => handleAnswerChange('q3', 'C')}
          />
          C. Rock
        </label>
        <label className="button">
          <input
            type="radio"
            name="q3"
            value="D"
            onChange={() => handleAnswerChange('q3', 'D')}
          />
          D. Jazz
        </label>
      </div>

      <div className="question" id="question4">
        <p>4. How do you handle stress?</p>
        <label className="button">
          <input
            type="radio"
            name="q4"
            value="A"
            onChange={() => handleAnswerChange('q4', 'A')}
          />
          A. Take a quiet break
        </label>
        <label className="button">
          <input
            type="radio"
            name="q4"
            value="B"
            onChange={() => handleAnswerChange('q4', 'B')}
          />
          B. Talk to friends or family
        </label>
        <label className="button">
          <input
            type="radio"
            name="q4"
            value="C"
            onChange={() => handleAnswerChange('q4', 'C')}
          />
          C. Engage in physical activity
        </label>
        <label className="button">
          <input
            type="radio"
            name="q4"
            value="D"
            onChange={() => handleAnswerChange('q4', 'D')}
          />
          D. Listen to music or watch something
        </label>
      </div>

      <div className="question" id="question5">
        <p>5. What is your dream vacation destination?</p>
        <label className="button">
          <input
            type="radio"
            name="q5"
            value="A"
            onChange={() => handleAnswerChange('q5', 'A')}
          />
          A. Quiet cabin in the mountains
        </label>
        <label className="button">
          <input
            type="radio"
            name="q5"
            value="B"
            onChange={() => handleAnswerChange('q5', 'B')}
          />
          B. Vibrant city with lots of activities
        </label>
        <label className="button">
          <input
            type="radio"
            name="q5"
            value="C"
            onChange={() => handleAnswerChange('q5', 'C')}
          />
          C. Tropical beach paradise
        </label>
        <label className="button">
          <input
            type="radio"
            name="q5"
            value="D"
            onChange={() => handleAnswerChange('q5', 'D')}
          />
          D. Cultural exploration in a historic city
        </label>
      </div>

      <button className="button submit" onClick={calculateResult}>
        Submit
      </button>

      {result && (
        <div id="result">
          <h2>Your Personality:</h2>
          <p>{result}</p>
          {renderScale()}
        </div>
      )}
    </div>
  );
};

export default App;
