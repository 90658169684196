/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
// SimulatedEntity.jsx
import React from 'react';
import './SimulatedEntity.css';

const SimulatedEntity = ({ entityData }) => {
  return (
    <div className="card-container">
      <h1 className="card-title">Simulated Candidate <span className="remove-option">- Remove</span></h1>
      
      <div className="profile-section">
        <img src={entityData.profileImageURL} alt="Profile" className="profile-image" />
        
        <div className="details">
          <h2 className="entity-name">{entityData.name}</h2>
          <p className="entity-role">{entityData.role}</p>
          <span className="affinity-indicator">{entityData.affinity}</span>
        </div>
        
        <div className="compatibility-bar-container">
          <div 
            className="compatibility-bar-filled" 
            style={{ width: `${entityData.affinityPercent}%` }}
          ></div>
        </div>
        
        <p className="description-text">{entityData.description}</p>
      </div>
    </div>
  );
}

export default SimulatedEntity;
