/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import React, { useState, useEffect } from 'react';

import SearchNav from '../../components/SearchNav';
import Loading from '../../components/Loading';
import ForumOverview from '../ForumOverview/ForumOverview';

// IDEA: Move axios service to a more sensible location
import { getForums, fetchProfileDetails } from '../../pages/axiosService';  // adjust path as needed


import './Search.css'
import ErrorMessage from '../ErrorMessage';


// const api = config.createApiUrl('events', `/event/`, config.URL_TYPES.API);

const Search = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [forums, setForums] = useState([]);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  // Additional state for profile details
  const [profileDetails, setProfileDetails] = useState({});

  const [beatStates, setBeatStates] = useState({
    faCalendarDays: false,
    faMagnifyingGlass: false,
    faGear: false,
  });

  useEffect(() => {
    // Define an asynchronous function to fetch forums data
    async function fetchForums() {
          setErrorMessage('');
          setIsLoading(true);
          try {
              // Attempt to fetch data using the getForums function
              const data = await getForums(search); // Assuming getForums is a valid function
              // Update the state with the fetched data
              setForums(data.forums);
              // Extract user IDs from threads and fetch profile details
              const userIds = extractUserIds(data.forums);
              if (userIds.length > 0) {
                  const profileData = await fetchProfileDetails(userIds);
                  setProfileDetails(profileData.reduce((acc, profile) => {
                      acc[profile.id] = profile; // Storing profiles by username for easy access
                      return acc;
                  }, {}));
              }
          } catch (error) {
              // If an error occurs during the fetch process, handle it appropriately
              console.error('Error fetching forums:', error);
              if (error.response?.status == 429) {
                setErrorMessage('Refresh the page a little slower, so that others can find their vibe, too.')
              }
              // You can add more specific error handling logic here, such as displaying an error message or setting a state variable
          } finally {
            setIsLoading(false);
          }
      }

      // Invoke the fetchForums function when the component mounts
      const debounceTimer = setTimeout(() => {
        fetchForums();
      }, 2000); // 2000ms delay

      // This will clear the timer if the user continues to type, ensuring that the API call is only made 2000ms after the user stops typing
      return () => clearTimeout(debounceTimer);
  }, [search]);

  // Function to extract user IDs
  const extractUserIds = (content) => {
    // IDEA: we would ideally get the author_id from all the messages
    const answer = [...new Set(content.map(forum => forum.author_id))]
    
    return answer;
  };
  
  const handleSearch = event => {
    setPage(1);
    setSearch(event.target.value);
  };

  if (errorMessage) {
    return (
      <div className='events_wrapper'>
        <ErrorMessage errorMessage={errorMessage} />
        <SearchNav search={search} handleSearch={handleSearch}/>
      </div>
    )
  }  

    
  if (isLoading) {
      return (
        <div className='events_wrapper'>
          <Loading />
          <SearchNav search={search} handleSearch={handleSearch}/>
        </div>
      )
  }
  
  return (
    <div>
      <div className='events_wrapper'>
        <div className='forums'>
          {forums.length > 0 ? (
            forums.map((forum, index) => (
                <div key={index} className="forum-item">
                  
                  <ForumOverview forum={forum} profileDetails={profileDetails} />
                    
                </div>
            ))
          ) : (
            <div className="no-events-message">
              <h1>No Forums Found</h1>
              <h5>Try a Different Search</h5>
            </div>
          )}
        </div>
      </div>
      <SearchNav search={search} handleSearch={handleSearch}/>
    </div>
  );
}

export default Search;
