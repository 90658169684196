/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faArrowsRotate, faBaby, faFaceGrinBeam, faDashboard, faGlobe, faHandshake, faLightbulb, faMedal, faPenToSquare, faQuestion, faRankingStar, faScrewdriverWrench, faScroll, faSeedling, faSpaceShuttle, faStar, faThumbsUp, faYen} from '@fortawesome/free-solid-svg-icons';


import './Rules.css'

const data = {
  "Open Dialogue": {
    icons: [faStar, faFaceGrinBeam],
    "Description": "Sharing and discussing ideas freely, focusing on constructive outcomes.",
    "Note": [
      "We encourage open discussions, acknowledging that disagreements and differing opinions are natural.",
      "Our platform welcomes a wide array of ideas, inviting thoughts that provoke and challenge."
    ]
  },
  "Respectful Conversations": {
    icons: [faThumbsUp, faHandshake],
    "Description": "Interactions where each viewpoint is expressed and listened to with courtesy.",
    "Note": [
      "We promote interactions based on mutual respect and constructive intent.",
      "Dialogue is used as a key tool for resolving conflicts and deepening understanding."
    ]
  },
  "Conflict Resolution": {
    icons: [faScrewdriverWrench, faLightbulb],
    "Description": "Working together to find solutions to disagreements through discussion.",
    "Note": [
      "We focus on responding to conflicts as they arise.",
      "We provide tools and guidelines to help users navigate and resolve disputes respectfully and productively."
    ]
  },
  "User Standards": {
    icons: [faMedal, faRankingStar],
    "Description": "Guidelines established by participants to ensure positive and meaningful exchanges.",
    "Note": [
      "We rely on our users to maintain standards that promote productive and respectful exchanges.",
      "They play a key role in fostering an open and respectful dialogue environment."
    ]
  },
  "Adaptable Guidelines": {
    icons: [faScroll, faSeedling],
    "Description": "Rules that evolve based on feedback to continuously improve discussion quality.",
    "Note": [
      "Our platform changes based on user feedback, adhering to core principles that uphold respectful and meaningful dialogue.",
      "We aim to maintain a space that reflects these guiding principles, adapting to the changing needs and expectations of users."
    ]
  }
};

// React component to render a section from the JSON data
const Section = ({ sectionName }) => {
  const sectionData = data[sectionName];

  if (!sectionData) {
    return <div >Section not found</div>;
  }

  /*
   * Exploration: 
   * (1) It's alright to break the code (and then hit CTRL-Z) :-D
   * (2) What does 'if (index == 0)' do?
   * (3) What would happen if you changed it to a different number after adding more icons?
   * icons: [faStar, faComment, fa.... (and many more icons)],
   * (4) There are quite a few curly braces and parentheses, aren't there? Try removing sections of the code
   */

  return (
    <div className='rules_page'>
      <div className='title_group'>
        <div className='title_sandwich'>
          {sectionData.icons.map((icon, index) => {
            if (index == 0) {
              return <FontAwesomeIcon key={index} icon={icon} />
            }
            if (index == 1) {
              return (
                <React.Fragment key={index}>
                  <div className='title_sandwich-text'>{sectionName}</div>
                  <FontAwesomeIcon icon={icon} />
                </React.Fragment>
              )
            }
          })} {/* These three '})}' are all matching on line 76 */}     
        </div>
        <h5>{sectionData.Description}</h5>
      </div>
      <ul>
        {sectionData.Note && sectionData.Note.map((note, index) => (
          <li key={index}>{note}</li>
        ))}
      </ul>
    </div>
  );
}

const Rules = () => {
  
  return (
    <div className='rules_container'>
      {data && Object.keys(data).map((key, index) => (
        <Section key={key} sectionName={key} />
      ))}
    </div>
  )
  
}
export default Rules;
  
