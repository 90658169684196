/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import axios from 'axios';
import config from '../config';

const IDENTITY_API_URL = config.createApiUrl('identity', '/', config.URL_TYPES.API);
const EVENT_API_URL = config.createApiUrl('events', '/', config.URL_TYPES.API);
const API_URL = config.createApiUrl('forum', '/', config.URL_TYPES.API);

export const fetchProfileDetails = async (userIds) => {
    try {
        const response = await axios.get(`${IDENTITY_API_URL}user/profile/simple-details`, {
            params: { ids: userIds }
        });
        return response.data
    } catch (error) {
        console.error('Error fetching profile details:', error);
    }
};

// Create Forum
export const createForum = async (data) => {
    const response = await axios.post(`${API_URL}forum/`, data);
    return response.data;
}

// Get Forums
export const getForums = async (searchValue) => {
    const response = await axios.get(`${API_URL}forum/`, {
        params: { 
            search: searchValue || '',
        }
    });
    return response.data;
}

// Create Message
export const createMessage = async (data) => {
    const response = await axios.post(`${API_URL}forum/messages`, data);
    return response.data;
}

// Get all content by forum
export const getAllContentByForum = async (forum_id) => {
    const response = await axios.get(`${API_URL}forum/forums/${forum_id}/all_content`);
    return response.data;
}

// Get event mapping infor by forum
export const getEventMappingByForum = async (forum_id) => {
    try {
        const response = await axios.get(`${EVENT_API_URL}forum/mapping`, {
            params: { forum_id: forum_id }
        });
        return response.data
    } catch (error) {
        console.error('Error fetching event mapping:', error);
    }
}

// Update Forum
export const updateForum = async (forum_id, data) => {
    const response = await axios.put(`${API_URL}forum/${forum_id}`, data);
    return response.data;
}
