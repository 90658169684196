/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import React, { useState, useEffect } from 'react';
import { getAllContentByForum, updateForum } from '../axiosService';  // assuming you've put Axios methods in a file called axiosService.js
import { useParams } from 'react-router-dom';
import { Link, useNavigate } from 'react-router-dom';
import ErrorMessage from '../../components/ErrorMessage'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCircleArrowLeft, faFloppyDisk} from '@fortawesome/free-solid-svg-icons';

import "./EditForum.css"

const EditForum = () => {
    let { id } = useParams();
    const forumId = id;
    const navigate = useNavigate();  // Get the navigate function


    const [forumDetails, setForumDetails] = useState({ name: '', description: '' });

    const [errorMessage, setErrorMessage] = useState(null);

    const iconLookup = {
        faCircleArrowLeft
    }

        // Fetch threads of the forum when component mounts
    useEffect(() => {
        async function fetchAllContent() {
            try {
                const data = await getAllContentByForum(forumId);
    
                if (data.error) {
                    console.error("Error fetching content:", data.error);
                    return;
                }
    
                // Set forum details
                setForumDetails(data.forum);
                
            } catch (error) {
                console.error("Error fetching content:", error);
            }
        }
    
        fetchAllContent();
    }, [forumId]);

    const handleUpdateForum = async () => {
        try {
            await updateForum(forumId, forumDetails);
            // Handle any necessary state changes or feedback to the user after updating
            navigate(`/forum/${forumId}`);  // Navigate to the new forum using its id
        } catch (error) {
            console.error("Error updating forum:", error);
            setErrorMessage(error.response.data.error)
            if (error.response.status == 429) {
                setErrorMessage('Take a deep breath, wait a minute, then update this forum')
            }
        }
    };

    return (
        
        <div>
            <div className='forum-nav'> 
                <Link to={`/forum/${forumId}`}>
                    <div className='button'><FontAwesomeIcon icon={faCircleArrowLeft} /> Back </div>
                </Link>
                <div className='button' onClick={handleUpdateForum}><FontAwesomeIcon icon={faFloppyDisk} />Update Forum</div>
            </div>
            {errorMessage &&
                <ErrorMessage errorMessage={errorMessage} />
            }
            <div> 
                <div className='input-group'>
                    <div className='input-container'>
                        <label>
                            Name
                        </label>
                        <input 
                            value={forumDetails.name} 
                            onChange={(e) => setForumDetails(prev => ({ ...prev, name: e.target.value }))}
                            placeholder="Forum Name"
                        />
                    </div>
                </div>
                <div className='input-group'>
                    <div className='input-container'>
                        <label>
                            Description
                        </label>
                        <input 
                            value={forumDetails.description} 
                            onChange={(e) => setForumDetails(prev => ({ ...prev, description: e.target.value }))}                           
                            placeholder="Forum Description"
                        />
                    </div>
                </div>
           </div>
        </div>
    );
};

export default EditForum;
