/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import React, { useState } from 'react';
import axios from 'axios';
import Modal from 'react-modal';

import Loading from '../../components/Loading';
import ErrorMessage from '../../components/ErrorMessage';
import config from '../../config';
import data from '../../data/test02.json';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookOpen, faLightbulb, faSeedling, faThumbsUp } from '@fortawesome/free-solid-svg-icons';

import './ForumPage.css';

const ForumPage = () => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [clickedReaction, setClickedReaction] = useState('');
    const [inputFieldValue, setInputFieldValue] = useState('');
    const [valueList, setValueList] = useState([]); // State variable for the list of values

    const title = data.title;
    const threads = data.threads;
    const subtitle = data.subtitle;

    const [expandedMessages, setExpandedMessages] = useState({});
    const [expandedReplies, setExpandedReplies] = useState({});

    if (isLoading) {
        return <Loading />;
    }

    const iconLookup = {
        inquire: faBookOpen,
        appreciate: faThumbsUp,
        innovate: faLightbulb,
        enhance: faSeedling,
    };

    const reactionClick = (reaction) => {
        setModalIsOpen(true);
        setClickedReaction(reaction);
    };

    const cancelMessage = () => {
        setModalIsOpen(false);
        setInputFieldValue('');
    };

    const toggleMessageExpand = (index) => {
        setExpandedMessages((prevExpandedMessages) => ({
            ...prevExpandedMessages,
            [index]: !prevExpandedMessages[index],
        }));
    };

    const toggleRepliesExpand = (index) => {
        setExpandedReplies((prevExpandedReplies) => ({
            ...prevExpandedReplies,
            [index]: !prevExpandedReplies[index],
        }));
    };

    const shouldTruncateMessage = (message) => message.length > 150;

    const handleInputChange = (e) => {
        setInputFieldValue(e.target.value);
    };

    const addToValueList = () => {
        if (inputFieldValue) {
            setValueList([...valueList, inputFieldValue]);
            setInputFieldValue(''); 
        }
    };

    const renderValueList = () => {
        return (
            <ul>
                {valueList.map((value, index) => (
                    <li key={index}>{value}</li>
                ))}
            </ul>
        );
    };

    return (
        <div>
            <Modal className='chat-modal' isOpen={modalIsOpen}>
                <div>
                    <FontAwesomeIcon
                        icon={iconLookup[clickedReaction]}
                        style={{ marginRight: '1rem' }}
                    />
                    <div>{clickedReaction}</div>
                    <ErrorMessage errorMessage={"Discussion Guide"} />
                    <h1>Hello wo</h1>
                    <h5>Are you sure you want to archive this organization?</h5>
                    <input
                        type='text'
                        value={inputFieldValue}
                        onChange={handleInputChange}
                    />
                    <button onClick={addToValueList}>Add to List</button>
                    <div>{inputFieldValue}</div>
                    {renderValueList()} {/* Render the list of values */}
                    <div className='org-nav'>
                        <div className="button" onClick={cancelMessage}>Back</div>
                        <div className="button" onClick={() => setModalIsOpen(false)}>Send</div >
                    </div>
                </div>
            </Modal>

            <div className='forum-fixed_wrapper'>
                <h1>{title}</h1>
                <h2>{subtitle}</h2>

                {threads.map((thread, index) => (
                    <div key={index}>
                        <div className='message'>
                            <h2>
                                <FontAwesomeIcon
                                    icon={iconLookup[thread.icon]}
                                    style={{ marginRight: '1rem' }}
                                />
                            </h2>
                            <p>
                                {shouldTruncateMessage(thread.message) &&
                                    !expandedMessages[index]
                                    ? thread.message.slice(0, 150) + '...'
                                    : thread.message}
                                {shouldTruncateMessage(thread.message) && (
                                    <span
                                        className='read-more'
                                        onClick={() => toggleMessageExpand(index)}
                                    >
                                        {expandedMessages[index] ? ' Read less' : ' Read more'}
                                    </span>
                                )}
                            </p>
                        </div>
                        <p
                            className='replies-toggle'
                            onClick={() => toggleRepliesExpand(index)}
                        >
                            {expandedReplies[index] ? 'Close Replies' : thread.replies.length + ' Replies'}
                        </p>

                        {expandedReplies[index] && (
                            <div className='replies-list'>
                                {thread.replies.map((reply, replyIndex) => (
                                    <div className='message' key={replyIndex}>
                                        <h5>
                                            <FontAwesomeIcon
                                                icon={iconLookup[reply.icon]}
                                                style={{ marginRight: '1rem' }}
                                            />
                                        </h5>
                                        <p>{reply.message}</p>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                ))}
            </div>
            <div className='grid'>

                <div className='row'>

                    <div onClick={() => reactionClick('appreciate')} className='grid-button'>
                        <FontAwesomeIcon icon={faThumbsUp} />
                        <h6>
                            Appreciate
                        </h6>
                    </div>

                    <div onClick={() => reactionClick('inquire')} className='grid-button'>
                        <FontAwesomeIcon icon={faBookOpen} />
                        <h6>
                            Inquire
                        </h6>
                    </div>

                </div>

                <div className='row'>

                    <div onClick={() => reactionClick('innovate')} className='grid-button'>
                        <FontAwesomeIcon icon={faLightbulb} />
                        <h6>
                            Innovate
                        </h6>
                    </div>

                    <div onClick={() => reactionClick('enhance')} className='grid-button'>
                        <FontAwesomeIcon icon={faSeedling} />
                        <h6>
                            Enhance
                        </h6>
                    </div>

                </div>

            </div>
        </div>
    );
};

export default ForumPage;
