/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
// React component
import React, { useState, useEffect } from 'react';
import './Messages.css'; // make sure to create this CSS file

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBinoculars, faLightbulb, faRocket, faThumbsUp, faCircleDown, faFilePen, faMagnifyingGlassPlus, faCircleLeft, faPaperPlane } from '@fortawesome/free-solid-svg-icons';

const iconLookup = {
    "1": faBinoculars,
    "2": faThumbsUp,
    "3": faLightbulb,
    "4": faRocket,
}

const Messages = ({threads, profileDetails, truncateCharacters}) => {

  const [visibleDates, setVisibleDates] = useState({});

  const convertDate = (milli) => {
      return new Date(milli).toUTCString().substring(0, 16)
  }

  const toggleDateVisibility = (messageId) => {
      setVisibleDates(prevVisibleDates => ({
          ...prevVisibleDates,
          [messageId]: !prevVisibleDates[messageId],
      }));
  };

  const checkProfilePicture = (message) => {
      let imagePath = 'https://revibe-life-assets.s3.amazonaws.com/critter021.webp';
      const pattern = /^critter\d{3}\.webp$/;

      const profilePicture = profileDetails[message.author_id]?.profile_picture;

      if (profilePicture != null && pattern.test(profilePicture)) {
          imagePath = `https://revibe-life-assets.s3.amazonaws.com/${profileDetails[message.author_id].profile_picture}`
      }

      return imagePath;
  }

  return (
    <>
        {threads.map(thread => (
            <div key={thread.id}>
                {thread.messages.map(message => (
                    <div key={message.id}>
                        {visibleDates[message.id] && (
                            <>
                                <div className='date'></div>
                                <div>
                                    <div className='message-details' title={profileDetails[message.author_id]?.username ? profileDetails[message.author_id]?.username : null}>
                                            {
                                                profileDetails[message.author_id]?.username ?
                                                truncateCharacters(
                                                    profileDetails[message.author_id]?.username, 16
                                                ) :
                                                '(No username)'
                                            }
                                        <div>
                                            {convertDate(message.created_at)}
                                        </div>
                                    </div>
                                </div>
                        </>
                        )}
                        <div className="message" onClick={() => toggleDateVisibility(message.id)}>
                            {message?.vibe_type_id &&
                                <div className='message-vibe_badge'>
                                    <FontAwesomeIcon
                                        icon={iconLookup[message?.vibe_type_id]}
                                    />
                                </div>
                            }
                            <div>
                                <img 
                                    className='profile-pic'
                                    src={checkProfilePicture(message)}
                                    />
                            </div>
                            <div className='message-content'>
                                {message.content}
                            </div>
                        </div>
                    </div>
                ))}
                {/* <button onClick={() => handleDeleteThread(thread.id)}>Delete</button> */}
            </div>
        ))}
    </>
  );
}

export default Messages;
